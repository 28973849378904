.details-view {
	z-index: 1;
	padding-top: 10px;
  padding-right: 25px;
}
.operational-view {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: calc(275px + (40 / 100) * (100% - 265px));
  width: calc((60 / 100) * (100% - 265px));
  background-color: white;
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  @media (max-width: 989px) {
    left: calc(25px + (40 / 100) * (100% - 25px));
    width: calc((60 / 100) * (100% - 25px));
  }
  @media (max-width: 568px) {
    width: 100%;
    left: auto;
  }
}

.hidden-column-xs {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  flex-direction: row;

  @media (max-width:320px)  { display: none;/* smartphones, iPhone, portrait 480x320 phones */ }
  @media (max-width:481px)  { display: none;/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
  @media (max-width:990px)  { display: none;/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
}

.operational-view-footer {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  padding-left: 25px;
  @media (max-width: 568px) {
    padding-left: 0px;
  }
}
.operational-view-title-container {
  padding-right: 35px;
  height: 65px;
  display: flex;
  flex-direction: row;
  background-color: #0c3a52;
  align-items: center;
  @media (max-width: 568px) {
    padding-right: 5px;
  }
}

.operational-view-title {
  margin: 0px;
  margin-right: 30px;
  margin-left: 20px;
  color: white;
  font-weight: bold;
}

.operational-view-container {
  height: 100%;
  padding: 20px;
  padding-bottom: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}


.icon-close {
  cursor: pointer;
  cursor: hand;
}
.icon-close:hover {
	color: #EEEEEE;
}


@media (max-width: 568px) {
	.operational-title {
		font-size: 19px;
	}
	.icon-close {
		font-size: 17px !important;
	}
}

