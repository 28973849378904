.table-align-center {
	text-align: center;
}
.panel-heading {
	border-radius: 0px;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #337ab7;
	padding: 5px;
}
.panel-body {
	padding: 0px;
}
