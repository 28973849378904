.list-view {
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
.list-view-header {
	z-index: 100;
	position: fixed;
	color: white;
	padding-left: 20px;
	justify-content: flex-start;
	top: 64px;
	flex-direction: row;
	display: flex;
	align-items: center;
	@media (min-width: 320px) and (max-width: 480px) {
		zoom: 80% !important;
		top: 90px;
	  }

	  box-shadow: -5px 12px 6px -8px rgba(0, 0, 0, 0.5);
	  -webkit-box-shadow: -5px 12px 6px -8px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -5px 12px 6px -8px rgba(0, 0, 0, 0.5);
}

.scroll-div {
	height: 100vh;
	overflow: scroll;
}

.button-add-entity {
	padding: 10px !important;
	margin-left: 20px;
	height: auto;
	border-radius: 50%;
}

.log-row {
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d6d4d5;
    min-height: 100px;
    background-color: white;
}