.bounce-enter {
  transform: translateX(50%);
  opacity: 0.01;
}

.bounce-enter.bounce-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-out;
}

.bounce-leave {
  transform: translateX(0%);	
  opacity: 1;
}

.bounce-leave.bounce-leave-active {
  transform: translateX(0%);
  transform: translateX(50%);
  opacity: 0.01;
  transition: all 300ms ease-out;
}

.bounceUp-enter {
  transform: translateY(50%);  
  opacity: 0.01;
}

.bounceUp-enter.bounceUp-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 300ms ease-out;
}

.bounceUp-leave {
  transform: translateY(0%);
  opacity: 1;
}

.bounceUp-leave.bounceUp-leave-active {
  transform: translateY(0%);
  transform: translateY(50%);
  opacity: 0.01;
  transition: all 300ms ease-out;
}

.fadeInOut-enter {
  opacity: 0.01;
}

.fadeInOut-enter.fadeInOut-enter-active {
  opacity: 1;
  transition: all 500ms ease-out;
}

.fadeInOut-leave {
  opacity: 1;
}

.fadeInOut-leave.fadeInOut-leave-active {
  opacity: 0;
  transition: all 200ms ease-out;
}