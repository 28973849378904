.resourceInfoRow-card {
  border-radius: 10px;
  padding: 0;
  margin-bottom: 25px;
  position: relative;
}

.resourceInfoRow-avatar {
  color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  height: 45px;
  width: 45px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0;
}

.resourceInfoRow-typeTag {
  background-color: #1B97C2;
  border-radius: 4px;
  padding: 5px !important;
  width: fit-content;
  margin-top: 10px !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)
}

.resourceInfoRow-typeTag-flat {
  background-color: #1B97C2;
  border-radius: 4px;
  padding: 5px !important;
  width: fit-content;
}

.resourceInfoRow-AvatarTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.resourceInfoRow-editIcon {
  font-size: 20px;
}

.resourceInfoRow-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  color: white;
}

.resourceSelectableRow-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  color: white;
  width: 100%;
  padding: 5px;
}

.resourceInfoRow-titleType {
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  padding-top: 5px;
}

.resourceInfoRow-titleType * {
  margin: 0;
  padding: 0;
}

.resourceInfoRow-header p {
  font-weight: normal;
}

.resourceInfoRow-info {
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 95%;
}

.resourceInfoRow-info-col {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.resourceInfoRow-info-col-icon {
  font-size: 18px;
  color: #222222;
}

.resourceInfoRow-info-col p {
  margin: 0;
  font-size: 80%;
}

.resourceInfoRow-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #157495;
  padding: 5px 10px 0px 10px;
  color: white;
}

.resourceInfoRow-icon {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.resourceInfoRow-bottom p {
  padding-left: 10px;
  text-align: left;
  margin: 0;
}