@import "variables";
@import "rubix/rubix";
@import "rubix/vendor";
@import "colors";
@import "theme";

@import "fonts/lato/lato";
@import "fonts/fontello/fontello";
@import "fonts/climacons/climacons";
@import "fonts/devicon/devicon";
@import "fonts/dripicons/dripicons";
@import "fonts/feather/feather";
@import "fonts/flatline/flatline";
@import "fonts/mfizz/mfizz";
@import "fonts/nargela/nargela";
@import "fonts/glyphicons/glyphicons";
@import "fonts/ikons/ikons";
@import "fonts/outlined/outlined";
@import "fonts/pixelvicon/pixelvicon";
@import "fonts/Simple-Line-Icons/Simple-Line-Icons";
@import "fonts/Stroke-Gap-Icons/Stroke-Gap-Icons";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "~react-step-progress-bar/styles.css";
/* Page styles */
@import "pages/home";
@import "pages/auth";
@import "components/cardView";
@import "components/detailsOperationalView";
@import "components/listView";
@import "components/table";
@import "components/credentialCard";
@import "components/Spinner";
@import "components/cardCredentialForm";
@import "components/alert";
@import "animations";
@import "components/calendar";
@import "components/resourceInfoRow";
@import "components/avatarSelector";
@import "components/global-loader";

@import "routes/common";

@import '~react-dates/lib/css/_datepicker.css';

@import '~antd/dist/antd.css';

@import '~react-select/scss/default';
@import './sweetalert2.min.css';