.rbc-selected-cell {
    background-color: rgba(128,203,196, 0.2);
  }
  
  .rbc-day-bg:hover {
      cursor: pointer;
  }
  
  .rbc-toolbar {
    @media (max-width: 849px) {
      display: flex;
      flex-direction: column;
    }
  }
  
  .rbc-toolbar .rbc-toolbar-label {
    margin: 10px;
    font-size: 20px;
    color: #3f3f3f;
  }

.rbc-month-view {
  zoom: 130% !important;
}

.rbc-time-view {
  zoom: 125% !important;
}

.rbc-date-cell {
  font-size: 13px;
}
.rbc-header {
  font-size: 13px;
}
  
  
.SingleDatePicker {
  height: 20px;
  width: 100%;
  margin: 2px !important;
}

.DateInput_input {
  height: 30px;
  font-weight: 400;
  font-size: 13px;

}

.DateInput {
  background-color: transparent;
  border-radius: 5px;
  font-size: 15px !important;
  padding: 0px !important;
  text-align: center;
}

.DateRangePicker__picker {
  z-index: 5 !important;
  left: 0px !important;
    zoom: 80% !important;
    @media (min-width: 320px) and (max-width: 480px) {
        zoom: 60% !important;
      }
}

.DateRangePicker {
  margin: 6px !important;
}

.DateRangePickerInput__withBorder {
  border: 2px solid #d2d2d2;
}
.DateRangePickerInput_arrow_svg {
  height: 33px !important;
  width: 33px !important;
}

.SingleDatePickerInput {
  background-color: white;
  border-width: 2px;
  border-radius: 5px !important;
  height: 37px;
  display: flex !important;
}

.SingleDatePickerInput_calendarIcon {
  padding: 0px !important;
  margin-bottom: 2px !important;
}

.SingleDatePickerInput_clearDate {
  padding: 0px !important;
}

.SingleDatePickerInput_clearDate {
  top: 80% !important;
  padding: 0px !important; 
  margin-bottom: 2px !important;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 0px;
}

.DateInput__display-text--focused {
}


.ant-time-picker-input {
  min-height: 37px !important;
}

.SingleDatePickerInput .DateInput {
  width: 100%;
}

.SingleDatePickerInput .DateInput_input {
  padding: 16px 11px 9px;
}
