/* ADD/REMOVE/CHANGE THEMES HERE */

// Giving the default theme a name
@include theme-maker("default", (
  theme (
    color #157495,
    bg #157495
  ),
  sidebar (
		bg #ffffff,
		avatar (
			bg #157495,
		),
	),
  header (
	logout (
	  bg #157495,
	  hover-bg #12637f
	),
	bg #157495,
  ),
));

@include generate-bg-colors("default-theme", #12637f, white);

@include theme-maker("green", (
  theme (
    color #009688,
    bg #009688,
    active-bg #fff,
    active-color #fff
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #009688,
    ),
  ),
  header (
  logout (
    bg #009688,
    hover-bg #00796B
  ),
  bg #009688,
  ),
));
@include generate-bg-colors("green-theme", #00796B, white);

@include theme-maker("blue", (
  theme (
    color #3F51B5,
    bg #3F51B5
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #3F51B5,
    ),
  ),
  header (
  logout (
    bg #3F51B5,
    hover-bg #303F9F
  ),
  bg #3F51B5,
  ),
));
@include generate-bg-colors("blue-theme", #303F9F, white);


@include theme-maker("red", (
  theme (
    color #e95841,
    bg #e95841
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #e95841,
    ),
  ),
  header (
  logout (
    bg #e95841,
    hover-bg #f3a599
  ),
  bg #e95841,
  ),
));

@include generate-bg-colors("red-theme", #eb6753, white);

@include theme-maker("orange", (
  theme (
    color #FF9800,
    bg #FF9800
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #FF9800,
    ),
  ),
  header (
  logout (
    bg #FF9800,
    hover-bg #F57C00
  ),
  bg #FF9800,
  ),
));
@include generate-bg-colors("orange-theme", #F57C00, white);

@include theme-maker("purple", (
  theme (
    color #673AB7,
    bg #673AB7
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #673AB7,
    ),
  ),
  header (
  logout (
    bg #673AB7,
    hover-bg #512DA8
  ),
  bg #673AB7,
  ),
));
@include generate-bg-colors("purple-theme", #512DA8, white);


@include theme-maker("dark", (
  theme (
    color #1E1E1E,
    bg #1E1E1E,
    active-bg #fff,
    active-color #fff
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #1E1E1E,
    ),
  ),
  header (
  logout (
    bg #1E1E1E,
    hover-bg #656565
  ),
  bg #1E1E1E,
  ),
));
@include generate-bg-colors("dark-theme", #121212, white);

@include theme-maker("gray", (
  theme (
    color #8c867b,
    bg #8c867b,
    active-bg #fff,
    active-color #fff
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #8c867b,
    ),
  ),
  header (
  logout (
    bg #8c867b,
    hover-bg #817d7d
  ),
  bg #8c867b,
  ),
));
@include generate-bg-colors("gray-theme", #a4a4a4, white);


@include theme-maker("dark-brown", (
  theme (
    color #59454a,
    bg #59454a,
    active-bg #fff,
    active-color #fff
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #59454a,
    ),
  ),
  header (
  logout (
    bg #59454a,
    hover-bg #59454a
  ),
  bg #59454a,
  ),
));
@include generate-bg-colors("dark-brown-theme", #59454a, white);

@include theme-maker("dark-gray", (
  theme (
    color #232a35,
    bg #232a35,
    active-bg #fff,
    active-color #fff
  ),
  sidebar (
    bg #ffffff,
    avatar (
      bg #232a35,
    ),
  ),
  header (
  logout (
    bg #232a35,
    hover-bg #232a35
  ),
  bg #232a35,
  ),
));
@include generate-bg-colors("dark-gray-theme", #232a35, white);


