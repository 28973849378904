@font-face {
  font-family: 'devicon';
  src: url('devicon.woff2') format('woff2'),
       url('devicon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="devicon-"], [class*=" devicon-"] {
  font-family: 'devicon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* PostgreSQL */
.devicon-postgresql-plain:before {
  content: "\e648";
}
.devicon-postgresql-plain-wordmark:before {
  content: "\e649";
}
.devicon-postgresql-plain.colored, .devicon-postgresql-plain-wordmark.colored {
  color: #336791;
}
/* NodeJS */
.devicon-nodejs-plain:before {
  content: "\e64a";
}
.devicon-nodejs-plain-wordmark:before {
  content: "\e64b";
}
.devicon-nodejs-plain.colored, .devicon-nodejs-plain-wordmark.colored {
  color: #83CD29;
}
/* MongoDB */
.devicon-mongodb-plain:before {
  content: "\e64c";
}
.devicon-mongodb-plain-wordmark:before {
  content: "\e64d";
}
.devicon-mongodb-plain.colored, .devicon-mongodb-plain-wordmark.colored {
  color: #4FAA41;
}
/* Kraken JS */
.devicon-krakenjs-plain:before {
  content: "\e64e";
}
.devicon-krakenjs-plain-wordmark:before {
  content: "\e64f";
}
.devicon-krakenjs-plain.colored, .devicon-krakenjs-plain-wordmark.colored {
  color: #0081C2;
}
/* Travis */
.devicon-travis-plain-wordmark:before {
  content: "\e641";
}
.devicon-travis-plain:before {
  content: "\e642";
}
.devicon-travis-plain-wordmark.colored, .devicon-travis-plain.colored {
  color: #BB2031;
}
/* Linux */
.devicon-linux-plain:before {
  content: "\e647";
}
.devicon-linux-plain.colored {
  color: #000000;
}
/* Ubuntu */
.devicon-ubuntu-plain-wordmark:before {
  content: "\e645";
}
.devicon-ubuntu-plain:before {
  content: "\e646";
}
.devicon-ubuntu-plain-wordmark.colored, .devicon-ubuntu-plain.colored {
  color: #DD4814;
}
/* Debian */
.devicon-debian-plain-wordmark:before {
  content: "\e643";
}
.devicon-debian-plain:before {
  content: "\e644";
}
.devicon-debian-plain-wordmark.colored, .devicon-debian-plain.colored {
  color: #A80030;
}
/* Firefox */
.devicon-firefox-plain-wordmark:before {
  content: "\e63f";
}
.devicon-firefox-plain:before {
  content: "\e640";
}
.devicon-firefox-plain-wordmark.colored, .devicon-firefox-plain.colored {
  color: #DD732A;
}
/* Chrome */
.devicon-chrome-plain-wordmark:before {
  content: "\e63d";
}
.devicon-chrome-plain:before {
  content: "\e63e";
}
.devicon-chrome-plain-wordmark.colored, .devicon-chrome-plain.colored {
  color: #ce4e4e;
}
/* Backbone */
.devicon-backbone-line-wordmark:before {
  content: "\e639";
}
.devicon-backbone-line:before {
  content: "\e63a";
}
.devicon-backbone-plain-wordmark:before {
  content: "\e63b";
}
.devicon-backbone-plain:before {
  content: "\e63c";
}
.devicon-backbone-line-wordmark.colored, .devicon-backbone-line.colored, .devicon-backbone-plain-wordmark.colored, .devicon-backbone-plain.colored {
  color: #002a41;
}
/* Ruby on rails */
.devicon-rails-plain-wordmark:before {
  content: "\e637";
}
.devicon-rails-plain:before {
  content: "\e638";
}
.devicon-rails-plain-wordmark.colored, .devicon-rails-plain.colored {
  color: #a62c46;
}
/* Ruby */
.devicon-ruby-plain-wordmark:before {
  content: "\e635";
}
.devicon-ruby-plain:before {
  content: "\e636";
}
.devicon-ruby-plain-wordmark.colored, .devicon-ruby-plain.colored {
  color: #d91404;
}
/* .NET */
.devicon-dot-net-plain-wordmark:before {
  content: "\e633";
}
.devicon-dot-net-plain:before {
  content: "\e634";
}
.devicon-dot-net-plain-wordmark.colored, .devicon-dot-net-plain.colored {
  color: #1384c8;
}
/* Gulp */
.devicon-gulp-plain:before {
  content: "\e632";
}
.devicon-gulp-plain.colored {
  color: #eb4a4b;
}
/* Zend */
.devicon-zend-plain-wordmark:before {
  content: "\e600";
}
.devicon-zend-plain:before {
  content: "\e601";
}
.devicon-zend-plain-wordmark.colored, .devicon-zend-plain.colored {
  color: #68b604;
}
/* Yii */
.devicon-yii-plain-wordmark:before {
  content: "\e602";
}
.devicon-yii-plain:before {
  content: "\e603";
}
.devicon-yii-plain-wordmark.colored, .devicon-yii-plain.colored {
  color: #0073bb;
}
/* Wordpress */
.devicon-wordpress-plain-wordmark:before {
  content: "\e604";
}
.devicon-wordpress-plain:before {
  content: "\e605";
}
.devicon-wordpress-plain-wordmark.colored, .devicon-wordpress-plain.colored {
  color: #494949;
}
/* Sass */
.devicon-sass-plain:before {
  content: "\e606";
}
.devicon-sass-plain.colored {
  color: #cc6699;
}
/* Python */
.devicon-python-plain-wordmark:before {
  content: "\e607";
}
.devicon-python-plain:before {
  content: "\e608";
}
.devicon-python-plain-wordmark.colored, .devicon-python-plain.colored {
  color: #ffd845;
}
/* PHP */
.devicon-php-plain:before {
  content: "\e609";
}
.devicon-php-plain.colored {
  color: #6181b6;
}
/* Photoshop */
.devicon-photoshop-line:before {
  content: "\e60a";
}
.devicon-photoshop-plain:before {
  content: "\e60b";
}
.devicon-photoshop-line.colored, .devicon-photoshop-plain.colored {
  color: #80b5e2;
}
/* MySQL */
.devicon-mysql-line:before {
  content: "\e60d";
}
.devicon-mysql-line-wordmark:before {
  content: "\e60c";
}
.devicon-mysql-line.colored, .devicon-mysql-line-wordmark.colored {
  color: #00618a;
}
/* Less */
.devicon-less-plain:before {
  content: "\e60e";
}
.devicon-less-plain.colored {
  color: #2a4d80;
}
/* Laravel */
.devicon-laravel-plain-wordmark:before {
  content: "\e60f";
}
.devicon-laravel-plain:before {
  content: "\e610";
}
.devicon-laravel-plain-wordmark.colored, .devicon-laravel-plain.colored {
  color: #fd4f31;
}
/* JQuery */
.devicon-jquery-plain-wordmark:before {
  content: "\e611";
}
.devicon-jquery-plain:before {
  content: "\e612";
}
.devicon-jquery-plain-wordmark.colored, .devicon-jquery-plain.colored {
  color: #0769ad;
}
/* Javascript */
.devicon-javascript-plain:before {
  content: "\e613";
}
.devicon-javascript-plain.colored {
  color: #f0db4f;
}
/* Java */
.devicon-java-line-wordmark:before {
  content: "\e614";
}
.devicon-java-line:before {
  content: "\e615";
}
.devicon-java-line-wordmark.colored, .devicon-java-line.colored {
  color: #ea2d2e;
}
/* Illustrator */
.devicon-illustrator-line:before {
  content: "\e616";
}
.devicon-illustrator-plain:before {
  content: "\e617";
}
.devicon-illustrator-line.colored, .devicon-illustrator-plain.colored {
  color: #faa625;
}
/* HTML5 */
.devicon-html5-plain-wordmark:before {
  content: "\e618";
}
.devicon-html5-plain:before {
  content: "\e619";
}
.devicon-html5-plain-wordmark.colored, .devicon-html5-plain.colored {
  color: #e54d26;
}
/* Grunt */
.devicon-grunt-line-wordmark:before {
  content: "\e61a";
}
.devicon-grunt-line:before {
  content: "\e61b";
}
.devicon-grunt-plain-wordmark:before {
  content: "\e61c";
}
.devicon-grunt-plain:before {
  content: "\e61d";
}
.devicon-grunt-line-wordmark.colored, .devicon-grunt-line.colored, .devicon-grunt-plain-wordmark.colored, .devicon-grunt-plain.colored {
  color: #fcaa1a;
}
/* Git */
.devicon-git-plain-wordmark:before {
  content: "\e61e";
}
.devicon-git-plain:before {
  content: "\e61f";
}
.devicon-git-plain-wordmark.colored, .devicon-git-plain.colored {
  color: #f34f29;
}
/* Foundation */
.devicon-foundation-plain-wordmark:before {
  content: "\e620";
}
.devicon-foundation-plain:before {
  content: "\e621";
}
.devicon-foundation-plain-wordmark.colored, .devicon-foundation-plain.colored {
  color: #008cba;
}
/* Devicon */
.devicon-devicon-line:before {
  content: "\e622";
}
.devicon-devicon-plain:before {
  content: "\e623";
}
.devicon-devicon-line.colored, .devicon-devicon-plain.colored {
  color: #60BE86;
}
/* CSS3 */
.devicon-css3-plain-wordmark:before {
  content: "\e624";
}
.devicon-css3-plain:before {
  content: "\e625";
}
.devicon-css3-plain-wordmark.colored, .devicon-css3-plain.colored {
  color: #3d8fc6;
}
/* Codeigniter */
.devicon-codeigniter-plain-wordmark:before {
  content: "\e626";
}
.devicon-codeigniter-plain:before {
  content: "\e627";
}
.devicon-codeigniter-plain-wordmark.colored, .devicon-codeigniter-plain.colored {
  color: #ee4323;
}
/* Bower */
.devicon-bower-line-wordmark:before {
  content: "\e628";
}
.devicon-bower-line:before {
  content: "\e629";
}
.devicon-bower-plain-wordmark:before {
  content: "\e62a";
}
.devicon-bower-plain:before {
  content: "\e62b";
}
.devicon-bower-line-wordmark.colored, .devicon-bower-line.colored, .devicon-bower-plain-wordmark.colored, .devicon-bower-plain.colored {
  color: #ef5734;
}
/* Bootstrap */
.devicon-bootstrap-line-wordmark:before {
  content: "\e62c";
}
.devicon-bootstrap-line:before {
  content: "\e62d";
}
.devicon-bootstrap-plain-wordmark:before {
  content: "\e62e";
}
.devicon-bootstrap-plain:before {
  content: "\e62f";
}
.devicon-bootstrap-line-wordmark.colored, .devicon-bootstrap-line.colored, .devicon-bootstrap-plain-wordmark.colored, .devicon-bootstrap-plain.colored {
  color: #59407f;
}
/* AngularJS */
.devicon-angularjs-plain-wordmark:before {
  content: "\e630";
}
.devicon-angularjs-plain:before {
  content: "\e631";
}
.devicon-angularjs-plain-wordmark.colored, .devicon-angularjs-plain.colored {
  color: #c4473a;
}
