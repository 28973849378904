@font-face {
  font-family: 'Stroke-Gap-Icons';
  src: url('Stroke-Gap-Icons.woff2') format('woff2'),
       url('Stroke-Gap-Icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-stroke-gap-icons-"]:before,
[class*=" icon-stroke-gap-icons-"]:before {
  font-family: 'Stroke-Gap-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-stroke-gap-icons-WorldWide:before {
  content: "\e600";
}
.icon-stroke-gap-icons-WorldGlobe:before {
  content: "\e601";
}
.icon-stroke-gap-icons-Underpants:before {
  content: "\e602";
}
.icon-stroke-gap-icons-Tshirt:before {
  content: "\e603";
}
.icon-stroke-gap-icons-Trousers:before {
  content: "\e604";
}
.icon-stroke-gap-icons-Tie:before {
  content: "\e605";
}
.icon-stroke-gap-icons-TennisBall:before {
  content: "\e606";
}
.icon-stroke-gap-icons-Telesocpe:before {
  content: "\e607";
}
.icon-stroke-gap-icons-Stop:before {
  content: "\e608";
}
.icon-stroke-gap-icons-Starship:before {
  content: "\e609";
}
.icon-stroke-gap-icons-Starship2:before {
  content: "\e60a";
}
.icon-stroke-gap-icons-Speaker:before {
  content: "\e60b";
}
.icon-stroke-gap-icons-Speaker2:before {
  content: "\e60c";
}
.icon-stroke-gap-icons-Soccer:before {
  content: "\e60d";
}
.icon-stroke-gap-icons-Snikers:before {
  content: "\e60e";
}
.icon-stroke-gap-icons-Scisors:before {
  content: "\e60f";
}
.icon-stroke-gap-icons-Puzzle:before {
  content: "\e610";
}
.icon-stroke-gap-icons-Printer:before {
  content: "\e611";
}
.icon-stroke-gap-icons-Pool:before {
  content: "\e612";
}
.icon-stroke-gap-icons-Podium:before {
  content: "\e613";
}
.icon-stroke-gap-icons-Play:before {
  content: "\e614";
}
.icon-stroke-gap-icons-Planet:before {
  content: "\e615";
}
.icon-stroke-gap-icons-Pause:before {
  content: "\e616";
}
.icon-stroke-gap-icons-Next:before {
  content: "\e617";
}
.icon-stroke-gap-icons-MusicNote2:before {
  content: "\e618";
}
.icon-stroke-gap-icons-MusicNote:before {
  content: "\e619";
}
.icon-stroke-gap-icons-MusicMixer:before {
  content: "\e61a";
}
.icon-stroke-gap-icons-Microphone:before {
  content: "\e61b";
}
.icon-stroke-gap-icons-Medal:before {
  content: "\e61c";
}
.icon-stroke-gap-icons-ManFigure:before {
  content: "\e61d";
}
.icon-stroke-gap-icons-Magnet:before {
  content: "\e61e";
}
.icon-stroke-gap-icons-Like:before {
  content: "\e61f";
}
.icon-stroke-gap-icons-Hanger:before {
  content: "\e620";
}
.icon-stroke-gap-icons-Handicap:before {
  content: "\e621";
}
.icon-stroke-gap-icons-Forward:before {
  content: "\e622";
}
.icon-stroke-gap-icons-Footbal:before {
  content: "\e623";
}
.icon-stroke-gap-icons-Flag:before {
  content: "\e624";
}
.icon-stroke-gap-icons-FemaleFigure:before {
  content: "\e625";
}
.icon-stroke-gap-icons-Dislike:before {
  content: "\e626";
}
.icon-stroke-gap-icons-DiamondRing:before {
  content: "\e627";
}
.icon-stroke-gap-icons-Cup:before {
  content: "\e628";
}
.icon-stroke-gap-icons-Crown:before {
  content: "\e629";
}
.icon-stroke-gap-icons-Column:before {
  content: "\e62a";
}
.icon-stroke-gap-icons-Click:before {
  content: "\e62b";
}
.icon-stroke-gap-icons-Cassette:before {
  content: "\e62c";
}
.icon-stroke-gap-icons-Bomb:before {
  content: "\e62d";
}
.icon-stroke-gap-icons-BatteryLow:before {
  content: "\e62e";
}
.icon-stroke-gap-icons-BatteryFull:before {
  content: "\e62f";
}
.icon-stroke-gap-icons-Bascketball:before {
  content: "\e630";
}
.icon-stroke-gap-icons-Astronaut:before {
  content: "\e631";
}
.icon-stroke-gap-icons-WineGlass:before {
  content: "\e632";
}
.icon-stroke-gap-icons-Water:before {
  content: "\e633";
}
.icon-stroke-gap-icons-Wallet:before {
  content: "\e634";
}
.icon-stroke-gap-icons-Umbrella:before {
  content: "\e635";
}
.icon-stroke-gap-icons-TV:before {
  content: "\e636";
}
.icon-stroke-gap-icons-TeaMug:before {
  content: "\e637";
}
.icon-stroke-gap-icons-Tablet:before {
  content: "\e638";
}
.icon-stroke-gap-icons-Soda:before {
  content: "\e639";
}
.icon-stroke-gap-icons-SodaCan:before {
  content: "\e63a";
}
.icon-stroke-gap-icons-SimCard:before {
  content: "\e63b";
}
.icon-stroke-gap-icons-Signal:before {
  content: "\e63c";
}
.icon-stroke-gap-icons-Shaker:before {
  content: "\e63d";
}
.icon-stroke-gap-icons-Radio:before {
  content: "\e63e";
}
.icon-stroke-gap-icons-Pizza:before {
  content: "\e63f";
}
.icon-stroke-gap-icons-Phone:before {
  content: "\e640";
}
.icon-stroke-gap-icons-Notebook:before {
  content: "\e641";
}
.icon-stroke-gap-icons-Mug:before {
  content: "\e642";
}
.icon-stroke-gap-icons-Mastercard:before {
  content: "\e643";
}
.icon-stroke-gap-icons-Ipod:before {
  content: "\e644";
}
.icon-stroke-gap-icons-Info:before {
  content: "\e645";
}
.icon-stroke-gap-icons-Icecream2:before {
  content: "\e646";
}
.icon-stroke-gap-icons-Icecream1:before {
  content: "\e647";
}
.icon-stroke-gap-icons-Hourglass:before {
  content: "\e648";
}
.icon-stroke-gap-icons-Help:before {
  content: "\e649";
}
.icon-stroke-gap-icons-Goto:before {
  content: "\e64a";
}
.icon-stroke-gap-icons-Glasses:before {
  content: "\e64b";
}
.icon-stroke-gap-icons-Gameboy:before {
  content: "\e64c";
}
.icon-stroke-gap-icons-ForkandKnife:before {
  content: "\e64d";
}
.icon-stroke-gap-icons-Export:before {
  content: "\e64e";
}
.icon-stroke-gap-icons-Exit:before {
  content: "\e64f";
}
.icon-stroke-gap-icons-Espresso:before {
  content: "\e650";
}
.icon-stroke-gap-icons-Drop:before {
  content: "\e651";
}
.icon-stroke-gap-icons-Download:before {
  content: "\e652";
}
.icon-stroke-gap-icons-Dollars:before {
  content: "\e653";
}
.icon-stroke-gap-icons-Dollar:before {
  content: "\e654";
}
.icon-stroke-gap-icons-DesktopMonitor:before {
  content: "\e655";
}
.icon-stroke-gap-icons-Corkscrew:before {
  content: "\e656";
}
.icon-stroke-gap-icons-CoffeeToGo:before {
  content: "\e657";
}
.icon-stroke-gap-icons-Chart:before {
  content: "\e658";
}
.icon-stroke-gap-icons-ChartUp:before {
  content: "\e659";
}
.icon-stroke-gap-icons-ChartDown:before {
  content: "\e65a";
}
.icon-stroke-gap-icons-Calculator:before {
  content: "\e65b";
}
.icon-stroke-gap-icons-Bread:before {
  content: "\e65c";
}
.icon-stroke-gap-icons-Bourbon:before {
  content: "\e65d";
}
.icon-stroke-gap-icons-BottleofWIne:before {
  content: "\e65e";
}
.icon-stroke-gap-icons-Bag:before {
  content: "\e65f";
}
.icon-stroke-gap-icons-Arrow:before {
  content: "\e660";
}
.icon-stroke-gap-icons-Antenna2:before {
  content: "\e661";
}
.icon-stroke-gap-icons-Antenna1:before {
  content: "\e662";
}
.icon-stroke-gap-icons-Anchor:before {
  content: "\e663";
}
.icon-stroke-gap-icons-Wheelbarrow:before {
  content: "\e664";
}
.icon-stroke-gap-icons-Webcam:before {
  content: "\e665";
}
.icon-stroke-gap-icons-Unlinked:before {
  content: "\e666";
}
.icon-stroke-gap-icons-Truck:before {
  content: "\e667";
}
.icon-stroke-gap-icons-Timer:before {
  content: "\e668";
}
.icon-stroke-gap-icons-Time:before {
  content: "\e669";
}
.icon-stroke-gap-icons-StorageBox:before {
  content: "\e66a";
}
.icon-stroke-gap-icons-Star:before {
  content: "\e66b";
}
.icon-stroke-gap-icons-ShoppingCart:before {
  content: "\e66c";
}
.icon-stroke-gap-icons-Shield:before {
  content: "\e66d";
}
.icon-stroke-gap-icons-Seringe:before {
  content: "\e66e";
}
.icon-stroke-gap-icons-Pulse:before {
  content: "\e66f";
}
.icon-stroke-gap-icons-Plaster:before {
  content: "\e670";
}
.icon-stroke-gap-icons-Plaine:before {
  content: "\e671";
}
.icon-stroke-gap-icons-Pill:before {
  content: "\e672";
}
.icon-stroke-gap-icons-PicnicBasket:before {
  content: "\e673";
}
.icon-stroke-gap-icons-Phone2:before {
  content: "\e674";
}
.icon-stroke-gap-icons-Pencil:before {
  content: "\e675";
}
.icon-stroke-gap-icons-Pen:before {
  content: "\e676";
}
.icon-stroke-gap-icons-PaperClip:before {
  content: "\e677";
}
.icon-stroke-gap-icons-On-Off:before {
  content: "\e678";
}
.icon-stroke-gap-icons-Mouse:before {
  content: "\e679";
}
.icon-stroke-gap-icons-Megaphone:before {
  content: "\e67a";
}
.icon-stroke-gap-icons-Linked:before {
  content: "\e67b";
}
.icon-stroke-gap-icons-Keyboard:before {
  content: "\e67c";
}
.icon-stroke-gap-icons-House:before {
  content: "\e67d";
}
.icon-stroke-gap-icons-Heart:before {
  content: "\e67e";
}
.icon-stroke-gap-icons-Headset:before {
  content: "\e67f";
}
.icon-stroke-gap-icons-FullShoppingCart:before {
  content: "\e680";
}
.icon-stroke-gap-icons-FullScreen:before {
  content: "\e681";
}
.icon-stroke-gap-icons-Folder:before {
  content: "\e682";
}
.icon-stroke-gap-icons-Floppy:before {
  content: "\e683";
}
.icon-stroke-gap-icons-Files:before {
  content: "\e684";
}
.icon-stroke-gap-icons-File:before {
  content: "\e685";
}
.icon-stroke-gap-icons-FileBox:before {
  content: "\e686";
}
.icon-stroke-gap-icons-ExitFullScreen:before {
  content: "\e687";
}
.icon-stroke-gap-icons-EmptyBox:before {
  content: "\e688";
}
.icon-stroke-gap-icons-Delete:before {
  content: "\e689";
}
.icon-stroke-gap-icons-Controller:before {
  content: "\e68a";
}
.icon-stroke-gap-icons-Compass:before {
  content: "\e68b";
}
.icon-stroke-gap-icons-CompassTool:before {
  content: "\e68c";
}
.icon-stroke-gap-icons-ClipboardText:before {
  content: "\e68d";
}
.icon-stroke-gap-icons-ClipboardChart:before {
  content: "\e68e";
}
.icon-stroke-gap-icons-ChemicalGlass:before {
  content: "\e68f";
}
.icon-stroke-gap-icons-CD:before {
  content: "\e690";
}
.icon-stroke-gap-icons-Carioca:before {
  content: "\e691";
}
.icon-stroke-gap-icons-Car:before {
  content: "\e692";
}
.icon-stroke-gap-icons-Book:before {
  content: "\e693";
}
.icon-stroke-gap-icons-BigTruck:before {
  content: "\e694";
}
.icon-stroke-gap-icons-Bicycle:before {
  content: "\e695";
}
.icon-stroke-gap-icons-Wrench:before {
  content: "\e696";
}
.icon-stroke-gap-icons-Web:before {
  content: "\e697";
}
.icon-stroke-gap-icons-Watch:before {
  content: "\e698";
}
.icon-stroke-gap-icons-Volume:before {
  content: "\e699";
}
.icon-stroke-gap-icons-Video:before {
  content: "\e69a";
}
.icon-stroke-gap-icons-Users:before {
  content: "\e69b";
}
.icon-stroke-gap-icons-User:before {
  content: "\e69c";
}
.icon-stroke-gap-icons-UploadCLoud:before {
  content: "\e69d";
}
.icon-stroke-gap-icons-Typing:before {
  content: "\e69e";
}
.icon-stroke-gap-icons-Tools:before {
  content: "\e69f";
}
.icon-stroke-gap-icons-Tag:before {
  content: "\e6a0";
}
.icon-stroke-gap-icons-Speedometter:before {
  content: "\e6a1";
}
.icon-stroke-gap-icons-Share:before {
  content: "\e6a2";
}
.icon-stroke-gap-icons-Settings:before {
  content: "\e6a3";
}
.icon-stroke-gap-icons-Search:before {
  content: "\e6a4";
}
.icon-stroke-gap-icons-Screwdriver:before {
  content: "\e6a5";
}
.icon-stroke-gap-icons-Rolodex:before {
  content: "\e6a6";
}
.icon-stroke-gap-icons-Ringer:before {
  content: "\e6a7";
}
.icon-stroke-gap-icons-Resume:before {
  content: "\e6a8";
}
.icon-stroke-gap-icons-Restart:before {
  content: "\e6a9";
}
.icon-stroke-gap-icons-PowerOff:before {
  content: "\e6aa";
}
.icon-stroke-gap-icons-Pointer:before {
  content: "\e6ab";
}
.icon-stroke-gap-icons-Picture:before {
  content: "\e6ac";
}
.icon-stroke-gap-icons-OpenedLock:before {
  content: "\e6ad";
}
.icon-stroke-gap-icons-Notes:before {
  content: "\e6ae";
}
.icon-stroke-gap-icons-Mute:before {
  content: "\e6af";
}
.icon-stroke-gap-icons-Movie:before {
  content: "\e6b0";
}
.icon-stroke-gap-icons-Microphone2:before {
  content: "\e6b1";
}
.icon-stroke-gap-icons-Message:before {
  content: "\e6b2";
}
.icon-stroke-gap-icons-MessageRight:before {
  content: "\e6b3";
}
.icon-stroke-gap-icons-MessageLeft:before {
  content: "\e6b4";
}
.icon-stroke-gap-icons-Menu:before {
  content: "\e6b5";
}
.icon-stroke-gap-icons-Media:before {
  content: "\e6b6";
}
.icon-stroke-gap-icons-Mail:before {
  content: "\e6b7";
}
.icon-stroke-gap-icons-List:before {
  content: "\e6b8";
}
.icon-stroke-gap-icons-Layers:before {
  content: "\e6b9";
}
.icon-stroke-gap-icons-Key:before {
  content: "\e6ba";
}
.icon-stroke-gap-icons-Imbox:before {
  content: "\e6bb";
}
.icon-stroke-gap-icons-Eye:before {
  content: "\e6bc";
}
.icon-stroke-gap-icons-Edit:before {
  content: "\e6bd";
}
.icon-stroke-gap-icons-DSLRCamera:before {
  content: "\e6be";
}
.icon-stroke-gap-icons-DownloadCloud:before {
  content: "\e6bf";
}
.icon-stroke-gap-icons-CompactCamera:before {
  content: "\e6c0";
}
.icon-stroke-gap-icons-Cloud:before {
  content: "\e6c1";
}
.icon-stroke-gap-icons-ClosedLock:before {
  content: "\e6c2";
}
.icon-stroke-gap-icons-Chart2:before {
  content: "\e6c3";
}
.icon-stroke-gap-icons-Bulb:before {
  content: "\e6c4";
}
.icon-stroke-gap-icons-Briefcase:before {
  content: "\e6c5";
}
.icon-stroke-gap-icons-Blog:before {
  content: "\e6c6";
}
.icon-stroke-gap-icons-Agenda:before {
  content: "\e6c7";
}
