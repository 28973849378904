.spinner-overlay {
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}



.inner-spinner-overlay {
  margin: auto;
}

.loader {
    border: 12px solid #f3f3f3; /* Light grey */
    border-top: 12px solid #157495; /* Green */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    z-index: 10001;
    animation: spin 2s linear infinite;
}
.small {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3896c1; /* Green */
  width: 40px;
  height: 40px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.progress-bar-step-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}