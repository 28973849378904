@font-face {
  font-family: 'Lato';
  src: url('lato-bold-webfont.woff2') format('woff2'),
       url('lato-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('lato-bolditalic-webfont.woff2') format('woff2'),
       url('lato-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('lato-italic-webfont.woff2') format('woff2'),
       url('lato-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('lato-light-webfont.woff2') format('woff2'),
       url('lato-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('lato-regular-webfont.woff2') format('woff2'),
       url('lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
