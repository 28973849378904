.disabledCheckbox {
  cursor: default;  
}

.disabledCheckbox span {
  cursor: default;  
}

.disabledCheckbox input {
  cursor: default;  
}

.disabledCheckbox label {
  cursor: default;  
}