.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    transition: 0.3s;
    padding: 10px;
    margin-bottom: 15px;
}

.card-noHover {
    /* Add shadows to create the "card" effect */
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
   transition: 0.3s;
   padding: 10px;
   border-radius: 1px;
   margin-top: 15px;
   margin-bottom: 15px;
   background-color: white;
   z-index: 10000;
}

.hover-container:hover {
    background-color: #F6F6F6;
    cursor: pointer;
}
.access-card-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.access-card {
    min-height: 170px;
    border-radius: 10px;
}

.card:hover {
    background-color: #F6F6F6;
    cursor: pointer;
}

.card-selected {
    background-color: aliceblue !important;
}
.card-noHover:hover {
    cursor: auto;
    background-color: white;
}

.card-title {
	margin: 5px 15px 2px;
    font-weight: bold;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #3f3f3f;
}
.card-body {
	display: flex;
    flex-direction: row;
    align-items: center;
}

.card-border-hover :hover {
    border: 1px solid red;
}

.card-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: white;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    padding: 3px;
    padding-left: 2px;
    padding-right: 20px;
}
.card-inner-body {
	display: flex;
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    @media (max-width: 849px) {
        padding-left: 10px;
    }
}
.card-tag-presentational-container {
	align-items: center;
}
.tags-container {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
}
.card-icon {
	color: white;
}

.card-description {
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3f3f3f;
    text-align: left;
}
.lock-icon {
	color: white;
    width: 70px;
    height: 70px;
    border-radius: 50px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

@media (max-width: 849px) {
	.card-title {
		font-size: 18px;
        margin-right: 5px;
        width: 100%;
	}
	.card-description {
		font-size: 10px;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
	}
	.tags-container {
	    display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: center;
	}
}

.card-new-entity {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    transition: 0.3s;
    padding: 10px;
    border-radius: 1px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 50px;
}

.card-new-entity:hover {
    cursor: pointer;
    background-color: #F5F5F5;
}
