@include generateColors("primary", $brand-primary, white);
@include generateColors("success", $brand-success, white);
@include generateColors("color-tag", #e95841, white);
@include generateColors("info", $brand-info, white);
@include generateColors("warning", $brand-warning, white);
@include generateColors("danger", $brand-danger, white);
@include generateColors("white", #ffffff, white);
@include generateColors("black", #000000, white);
@include generateColors("deepred", #D71F4B, white);
@include generateColors("jago-purple", #5c33ae, white);
@include generateColors("jago-purple-light", #845ed0, white);
@include generateColors("red", #EA7882, white);
@include generateColors("lightred", #F09FA6, white);
@include generateColors("brightblue", #003BFF, white);
@include generateColors("darkblue", #4A90E2, white);
@include generateColors("blue", #79B0EC, white);
@include generateColors("lightblue50", #D2E0EB, white);
@include generateColors("lightblue", #A0C7F2, white);
@include generateColors("hoverblue", #E5EFFB, white);
@include generateColors("purple", #B4A1DD, white);
@include generateColors("lightpurple50", #C1B2E3, white);
@include generateColors("lightpurple", #D3C9EB, white);
@include generateColors("darkbrown", #AB4F45, white);
@include generateColors("brown", #A8553A, white);
@include generateColors("brown75", #C36849, white);
@include generateColors("brown60", #C67055, white);
@include generateColors("brown50", #D67F6B, white);
@include generateColors("brownishgreen", #948266, white);
@include generateColors("orange", #FFC497, white);
@include generateColors("paleorange", #EA8564, white);
@include generateColors("pinkishred", #EF6B5D, white);
@include generateColors("orange75", #EE682F, white);
@include generateColors("orange65", #FA824F, white);
@include generateColors("orange45", #FFC9A0, white);
@include generateColors("darkorange", #EBA068, white);
@include generateColors("lightorange", #FFD3B1, white);
@include generateColors("darkgreen", #306C67, white);
@include generateColors("darkgreen85", #36845E, white);
@include generateColors("darkgreen75", #518F41, white);
@include generateColors("darkgreen65", #66A655, white);
@include generateColors("darkgreen55", #68BD80, white);
@include generateColors("darkgreen50", #55A69F, white);
@include generateColors("darkgreen45", #2EB398, white);
@include generateColors("darkgreen40", #44C1AC, white);
@include generateColors("green", #55C9A6, white);
@include generateColors("lightgreen", #7CD5BA, white);
@include generateColors("hovergreen", #E5F7F1, white);
@include generateColors("brightyellow", #FCB621, white);
@include generateColors("brightyellow75", #F3C557, white);
@include generateColors("brightyellow65", #EDBC6C, white);
@include generateColors("yellow", #FADD7F, white);
@include generateColors("paleyellow", #DEBC85, white);
@include generateColors("lightyellow", #FBE499, white);
@include generateColors("hoveryellow", #FFF1E7, white);
@include generateColors("pink", #E299B7, white);
@include generateColors("lightpink", #ECBCD0, white);
@include generateColors("paleblue", #A5C7EC, white);
@include generateColors("palegreen", #A0DFCC, white);
@include generateColors("palepink", #E394B3, white);
@include generateColors("brownishgray", #64584C, white);
@include generateColors("brownishgray75", #776B5F, white);
@include generateColors("gray", #EAEDF1, white);
@include generateColors("darkgray", #999895, white);
@include generateColors("darkgray75", #B8B6B0, white);
@include generateColors("darkgray50", #B8C3C8, white);
@include generateColors("darkgray40", #CDCCC8, white);
@include generateColors("darkgray25", #DCDBD8, white);
@include generateColors("lightgray", #EEEDEB, white);
@include generateColors("lightgray50", #F1F0EE, white);
@include generateColors("black75", #3F3F3B, white);
@include generateColors("black50", #3B4648, white);
@include generateColors("davygray", #555555, white);
@include generateColors("darkgrayishblue75", #43525A, white);
@include generateColors("darkgrayishblue", #4C5D67, white);
@include generateColors("desaturateddarkblue", #515E78, white);
@include generateColors("desaturateddarkblue75", #3B465C, white);
@include generateColors("darkcyan", #68A0A5, white);
@include generateColors("grayishcyan", #92BDC1, white);
@include generateColors("orange-light", #FB8C00, white);
