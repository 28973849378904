.avatarSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 5px 10px 5px 0px;
  border-radius: 10px;
  padding: 0 !important;
  width: 50px;
  height: 50px;
}

.card-avatar {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
}